
require('./bootstrap');

$(document).ready(function(){
  $.each($('.price'), function(){
  var price = $(this).html();
  $(this).html(price.replace(/(\d*)(\.\d*)/,'<span>$1</span><small style="font-weight:bold;">$2</small>'));
  });
});

$(document).ready(function(){
  $('[data-toggle="tooltip"]').tooltip();
});
